import React from "react";
import Layout from "../../components/Layout";

export default function programaDeIngles() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_educativo/12.jpg)`}}
                    >
                        <h1>Programa de Inglés</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción:</h1>
                        <p className="services_section_1__paragraph">
                            Plataforma Educativa y libros para la enseñanza y aprendizaje del idioma Inglés. Grupo
                            empresarial líder en educación, cultura, ciencia y tecnología. 34 años de experiencia.
                            Presencia
                            en 14 países
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}